$primary-color: #007BFF;

.lesson-box {
    position: relative;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2); /* 4px = 0.25em, 8px = 0.5em */
    border-radius: 0.9375em; /* 15px = 0.9375em */
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    padding: 0.625em 0.625em 0.625em 5em; /* top, right, bottom, left */
    row-gap: 1em; /* 10px = 0.625em */

    &:hover {
        box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.25); /* 6px = 0.375em, 12px = 0.75em */
        transform: translateY(-0.1875em); /* 3px = 0.1875em */
    }

    .subject-icon-container {
        display: flex;
        height: 5.625em; /* 90px = 5.625em */
        width: 5.625em; /* 90px = 5.625em */

        .frame {
            width: 100%; 
            height: 100%; 
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .subject-icon {
                width: 100%; 
                height: auto; 
                object-fit: cover;
            }
        }
    }

    .subject-title-container {
        width: 11.25em; /* 180px = 11.25em */
        
        .subject-title {
            font-size: 0.9375em; /* 15px = 0.9375em */
            font-weight: bold;
            margin: 0;
        }
    }

    .rating-container {
        width: 9.375em; /* 150px = 9.375em */

        .teacher-rating {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.1875em; /* 3px = 0.1875em */

            .teacher-name {
                font-size: 0.9375em; /* 15px = 0.9375em */
                margin: 0;
            }

            .MuiRating-root {
                font-size: 1.25em; /* 20px = 1.25em */
            }
        }
    }

    .date-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1875em; /* 3px = 0.1875em */

        .date,
        .time {
            font-size: 0.9375em; /* 15px = 0.9375em */
            margin: 0;
        }
    }

    .price {
        font-size: 0.9375em; /* 15px = 0.9375em */
        font-weight: bold;
        color: $primary-color;
        text-align: center;
    }

}

// Media Queries for Responsive Adjustments
@media only screen and (max-width: 1196px) {
    .lesson-box {
        width: 100%;
    }
}

@media only screen and (max-width: 885px) { 
    .lesson-box {
        justify-content:unset;
        padding: 0.625em 0.625em 0.625em 2.5em;
        column-gap:2.5em;
    }
}

@media only screen and (max-width: 576px) { 
    .lesson-box {
        justify-content: space-between;
        column-gap: unset;
        .subject-icon-container {
            height: 3.75em; /* 60px = 3.75em */
            width: 3.75em;
        }

        .subject-title-container {
            width: 7.5em; /* 120px = 7.5em */

            .subject-title {
                font-size: 0.8125em; /* 13px = 0.8125em */
            }
        }

        .rating-container {
            width: 7.5em; /* 120px = 7.5em */

            .teacher-rating {
                .teacher-name {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }

                .MuiRating-root {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                }
            }
        }

        .date-time {
            .date,
            .time {
                font-size: 0.8125em;
            }
        }

        .price {
            font-size: 0.8125em;
        }
    }
}

@media only screen and (max-width: 480px) { 
    .lesson-box {
        .subject-title-container {
            width: 7.2em;

            .subject-title {
                font-size: 0.8125em;
            }
        }
    }
}

@media only screen and (max-width: 361px) {
    .lesson-box {
        .subject-icon-container {
            height: 3.125em; /* 50px = 3.125em */
            width: 3.125em; /* 50px = 3.125em */
        }

        .subject-title-container {
            width: 6.5625em; /* 105px = 6.5625em */
            
            .subject-title {
                font-size: 0.6875em; /* 11px = 0.6875em */
            }
        }

        .rating-container {
            width: 6.5625em;

            .teacher-rating {
                .teacher-name {
                    font-size: 0.6875em; /* 11px = 0.6875em */
                }
            }
        }

        .price {
            font-size: 0.6875em; /* 11px = 0.6875em */
        }
    }
}
