.msg-box-container {
  display: flex;
  flex-direction: column;
  border-radius: 0.3125em; /* 5px = 0.3125em */
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1); /* 2px 4px */
  transition: background-color 0.3s;

  .msg-box {
      padding: 1em; /* 16px = 1em */
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-bottom: 0.0625em solid #ccc; /* 1px = 0.0625em */
      column-gap: 5.3125em; /* 85px = 5.3125em */
      row-gap: 0.625em; /* 10px = 0.625em */

      .msg-icon {
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .msg-dtls {
          width: 6.25em; /* 100px = 6.25em */
          font-size: 0.9375em; /* 15px = 0.9375em */
          color: #333;
          font-weight: bold;
          text-align: center;
      }

      .msg-date {
          width: 12.5em; /* 200px = 12.5em */
          font-size: 0.9375em; /* 15px = 0.9375em */
          color: #666;
          text-align: center;
      }
  }

  .btn-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.625em; /* 10px = 0.625em */

      .btn {
          padding: 0.3125em 0.625em; /* 5px 10px = 0.3125em 0.625em */
          background-color: #f0ad4e;
          color: white;
          border: none;
          border-radius: 0.3125em; /* 5px = 0.3125em */
          cursor: pointer;
          font-size: 0.875em; /* 14px = 0.875em */

          &:hover {
              background-color: darken(#f0ad4e, 10%);
          }
      }
  }

  .msg-viewer {
      display: flex;
      justify-content: center;
      padding: 1.25em; /* 20px = 1.25em */

      div {
          width: 100%;
      }
  }
}
