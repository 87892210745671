.payment-box {
    box-shadow: 0em 0.0625em 0.125em 0em rgba(0, 0, 0, 0.4); /* Converted px to em */
    border-radius: 0.3125em; /* 5px = 0.3125em */
    background-color: rgb(244, 254, 255);
}

@media (max-width: 62em) { /* 992px = 62em */
    .payment-box {
        border-radius: 0em; /* 0px = 0em */
        box-shadow: 0em 0.0625em 0.125em 0em rgba(0, 0, 0, 0.4); /* Converted px to em */
    }
}
