.icon-container {
    display: flex;
    padding: 0.625em; /* 10px = 0.625em */
    height: 6.25em; /* 100px = 6.25em */
    background-color: white;
  
    .frame {
      width: 7.5em; /* 120px = 7.5em */
      height: 7.5em; /* 120px = 7.5em */
      overflow: hidden;
      border-radius: 50%; /* makes the frame circular */
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1875em solid orange; /* 3px = 0.1875em */
      box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.3); /* 6px 12px = 0.375em 0.75em */
      transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for hover effects */
  
      &:hover {
        transform: scale(1.1); /* Slightly larger scale up on hover */
        box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.4); /* 8px 16px = 0.5em 1em */
      }
  
      .icon {
        width: 100%; /* Adjusts width within the circular frame */
        height: 100%; /* Adjusts height within the circular frame */
        object-fit: cover; /* Ensures the photo covers the frame without distortion */
        border-radius: 50%; /* ensures the photo itself is also circular */
      }
    }
  }
  