.normal {
    border: 0.125em solid #e4e2e2; /* 2px = 0.125em */
    cursor: pointer;
    border-radius: 0.625em; /* 10px = 0.625em */
    background-color: transparent;
    padding: 0.625em; /* 10px = 0.625em */
    width: 6.25em; /* 100px = 6.25em */
    height: 6.25em; /* 100px = 6.25em */
}

.checked {
    border: 0.1875em solid #369935; /* 3px = 0.1875em */
}

.course-icon {
    width: 100%;
    height: 100%;
}
