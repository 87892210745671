/* src/components/LoginPage.scss */

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f5;
}

.welcome-title {
  font-size: 2em; /* 2rem converted to em*/
  font-weight: bold;
  margin-bottom: 0.5em; /* 0.5rem converted to em*/
  color: #333;
  text-align: center;
}

.subtitle {
  font-size: 1.1em; /* 1.1rem converted to em*/
  color: #666;
  margin-bottom: 1.5em; /* 1.5rem converted to em*/
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 0.9375em; /* 15px converted to em*/
  width: 100%;
  max-width: 18.75em; /* 300px converted to em*/
}

.login_btn {
  padding: 0.75em; /*12px converted to em*/
  font-size: 1.1em; /* 1.1rem converted to em*/
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 0.3125em; /* 5px converted to em*/
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.educator-btn {
  background-color: #0288d1; /* Blue for educators */
}

.educator-btn:hover {
  background-color: #0277bd;
}

.student-account-btn {
  background-color: #43a047; /* Green for students */
}

.student-account-btn:hover {
  background-color: #388e3c;
}
