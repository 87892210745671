$primary-color: #007BFF;
$border-radius: 0.625em; // 10px equivalent in em
$box-shadow: 0em 0.0625em 0.125em 0em rgba(0, 0, 0, 0.3); // 0px 1px 2px 0px equivalent in em

.std-meeting-req-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 0.625em; // 10px equivalent
    row-gap: 0.625em; // 10px equivalent
    column-gap: 5em; // 80px equivalent
    
    .subject-icon-container {
        display: flex;
        height: 5em; // 80px equivalent
        width: 5em; // 80px equivalent

        .frame {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .subject-icon {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    .date-time {
        width: 8.75em; // 140px equivalent
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1875em; // 3px equivalent

        .date,
        .time {
            font-size: 0.9375em; // 15px equivalent
            margin: 0;
        }
    }

    .price {
        width: 3.125em; // 50px equivalent
        font-size: 0.9375em; // 15px equivalent
        font-weight: bold;
        color: $primary-color;
    }

    .lesson-num {
        width: 6.25em; // 100px equivalent
        font-size: 0.9375em; // 15px equivalent
        font-weight: bold;
        color: black;
        text-align: center;
    }

    .status-container {
        width: 6.25em; // 100px equivalent
        display: flex;
        flex-direction: column;
        align-items: center;

        .status {
            font-size: 1.5em; // x-large equivalent
        }
    }

    .btn-custom {
        border-radius: 0.9375em; // 15px equivalent
        width: 5.3125em; // 85px equivalent
    }
}

@media only screen and (max-width: 30em) { // 480px equivalent
    .std-meeting-req-box {
        column-gap: 1.5625em; // 25px equivalent

        .subject-icon-container {
            height: 3.75em; // 60px equivalent
            width: 3.75em;
        }

        .lesson-num {
            width: 3.125em; // 50px equivalent
        }

        .subject-title-container {
            width: 7.5em; // 120px equivalent

            .subject-title {
                font-size: 0.8125em; // 13px equivalent
            }
        }

        .date-time {
            .date,
            .time {
                font-size: 0.8125em; // 13px equivalent
            }
        }
    }
}

@media only screen and (min-width: 30.0625em) and (max-width: 35.9375em) { // 481px - 575px equivalent
    .std-meeting-req-box {
        column-gap: 1.75em; // 28px equivalent

        .subject-icon-container {
            height: 4.375em; // 70px equivalent
            width: 4.375em;
        }

        .date-time {
            .date,
            .time {
                font-size: 0.8125em; // 13px equivalent
            }
        }
    }
}

@media only screen and (min-width: 36em) and (max-width: 47.9375em) { // 576px - 767px equivalent
    .std-meeting-req-box {
        column-gap: 2.5em; // 40px equivalent
    }
}

@media only screen and (min-width: 48em) and (max-width: 61.9375em) { // 768px - 991px equivalent
    .std-meeting-req-box {
        column-gap: 6.25em; // 100px equivalent
    }
}

@media only screen and (min-width: 62em) and (max-width: 74.75em) { // 992px - 1196px equivalent
    .std-meeting-req-box {
        column-gap: 3.125em; // 50px equivalent
    }
}
