.date-picker input {
  width: 9.375em; /* 150px = 9.375em */
  height: 2.5em; /* 40px = 2.5em */
  border-bottom-style: solid;
  border-color: rgb(189, 189, 189);
  text-align: center;
  border-radius: 0.3125em; /* 5px = 0.3125em */
}

.label-100-wrap {
  position: relative;
}

.label-100 {
  position: absolute;
  top: -1.375em; /* 22px = 1.375em */
  right: 0.625em; /* 10px = 0.625em */
  font-size: 0.875em; /* Small size, typically around 14px converted to em */
}
