.msg-wrap {
    width: 50%;
  }
  
  .msg-form {
    background: rgb(243, 240, 245);
    border-radius: 0.625em; /*10px converted to em*/
  }
  
  .contact-name {
    display: flex;
    flex-wrap: wrap;
    gap: 2.1875em; /*35px converted to em*/
  }
  
  @media (max-width: 62em) { /*992px converted to em*/
    .msg-wrap {
      width: 95%;
    }
  }
  