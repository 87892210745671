.tch-card-container {
    position: relative;
    width: 14em; // Default width for larger screens
    height: 15em; // Consistent max-height for all screens
    box-shadow: -0.1875em 0.25em 0.375em -0.125em rgba(0, 0, 0, 0.2); // -3px 4px 6px -2px
    border-radius: 0em; // 8px = 0.5em
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    padding-bottom: 1.25em; // 20px = 1.25em

    &:hover {
        box-shadow: -0.1875em 0.375em 0.75em -0.125em rgba(0, 0, 0, 0.25); // -3px 6px 12px -2px
        transform: translateY(-0.1875em); // 3px = 0.1875em
    }

    .tch-photo-container {
        display: flex;
        padding: 0.625em; // 10px = 0.625em
        height: 6.25em; // 100px = 6.25em
        background-color: white;

        .circle-frame {
            width: 5em; // 80px = 5em
            height: 5em;
            overflow: hidden;
            border-radius: 50%; 
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.1875em solid orange; // 3px = 0.1875em
            box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.3); // 0 6px 12px
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                transform: scale(1.1); // Slightly larger scale up on hover
                box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.4); // 0 8px 16px
            }

            .tch-photo {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); // 0 4px 8px
            }
        }
    }

    .card-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.625em; // 10px = 0.625em
        padding-bottom: 0.625em; // 10px = 0.625em

        .rating-container {
            .teacher-rating {
                display: flex;
                flex-direction: column;
                align-items: center;

                .teacher-name {
                    font-size: 1em; // 16px = 1em
                    font-weight: bold;
                    margin: 0;
                }

                .MuiRating-root {
                    font-size: 1.25em; // 20px = 1.25em
                }
            }
        }

        .follow-button {
            background-color: #3897f0; // blue color
            color: white;
            font-size: 1em; // 16px = 1em
            font-weight: bold;
            border-radius: 0.3125em; // 5px = 0.3125em
            border: none;
            cursor: pointer;
            height: 1.875em; // 30px = 1.875em
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// Media Queries for Responsive Adjustments

@media only screen and (max-width: 1281px) {
    .tch-card-container {
        width: 13.3em;
    }
}

@media only screen and (max-width: 1115px) {
    .tch-card-container {
        width: 16.3em;
    }
}

@media only screen and (max-width: 885px) {
    .tch-card-container {
        width: 17.3em;
    }
}

@media only screen and (max-width: 835px) {
    .tch-card-container {
        width: 16em;
    }
}

@media only screen and (max-width: 821px) {
    .tch-card-container {
        width: 15.8em;
    }
}

@media only screen and (max-width: 801px) {
    .tch-card-container {
        width: 15.4em;
    }
}

@media only screen and (max-width: 769px) {
    .tch-card-container {
        width: 14.7em;
    }
}

@media only screen and (max-width: 431px) {
    .tch-card-container {
        width: 11.9em;

        .card-box {
            .rating-container {
                .teacher-rating {
                    .teacher-name {
                        font-size: 0.8125em; // 13px
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 415px) {
    .tch-card-container {
        width: 11.5em;
    }
}

@media only screen and (max-width: 394px) {
    .tch-card-container {
        width: 10.8em;

        .card-box {
            .rating-container {
                .teacher-rating {
                    .teacher-name {
                        font-size: 0.75em; // 12px
                    }

                    .MuiRating-root {
                        font-size: 0.875em; // 14px
                    }
                }

                .follow-button {
                    font-size: 0.875em; // Reduced button text size for smaller screens
                }
            }
        }
    }
}

@media only screen and (max-width: 376px) {
    .tch-card-container {
        width: 10.4em;
    }
}

@media only screen and (max-width: 361px) {
    .tch-card-container {
        width: 9.9em;

        .card-box {
            .rating-container {
                .teacher-rating {
                    .teacher-name {
                        font-size: 0.6875em; // 11px
                    }

                    .MuiRating-root {
                        font-size: 0.75em; // 12px
                    }
                }

                .follow-button {
                    font-size: 0.75em; // Reduced button text size
                    width: 85%; // Slightly reduce width for better spacing
                }
            }
        }
    }
}

@media only screen and (max-width: 321px) {
    .tch-card-container {
        width: 8.2em;

        .card-box {
            .rating-container {
                .teacher-rating {
                    .teacher-name {
                        font-size: 0.6875em; // 11px
                    }
                }
            }
        }
    }
}
