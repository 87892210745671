/* Custom styles for Start Lesson buttons */
.start-lesson-btn {
    border-radius: 20px; /* Default border radius */
    width: 130px; /* Default width */
    font-size: 13px; /* Default font size */
    padding: 8px 15px; /* Default padding */
    transition: all 0.3s ease; /* Smooth resizing on hover */
}

/* Adjust styles for screens smaller than 576px */
@media only screen and (max-width: 576px) {
    .start-lesson-btn {
        width: 100px !important; /* Reduce button width */
        font-size: 12px !important; /* Adjust font size */
        padding: 5px 10px !important; /* Reduce padding */
        border-radius: 15px !important; /* Optional: Adjust border radius */
    }

    .start-lesson-btn span {
        font-size: 10px !important; /* Reduce font size for text inside the button */
    }

    /* Optional: Adjust icons or loading spinner sizes inside buttons */
    .start-lesson-btn .ReactLoading {
        height: 15px !important;
        width: 15px !important;
    }
}
