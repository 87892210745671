.m-label {
    font-size: 0.875em; /* small font size is approximately 14px = 0.875em */
}

.credit-image {
    width: auto;
    height: 2.1875em; /* 35px = 2.1875em */
}

@media (max-width: 30em) { /* 480px = 30em */
    .credit-image {
        height: 1.25em; /* 20px = 1.25em */
    }
}

.paypal-image {
    width: auto;
    height: 2.1875em; /* 35px = 2.1875em */
}

@media (max-width: 30em) { /* 480px = 30em */
    .paypal-image {
        height: 1.25em; /* 20px = 1.25em */
    }
}
