.social-btn {
    width: 100%;
    height: 2.8125em; /* 45px = 2.8125em */
    border: none;
    border-radius: 0.25em; /* 4px = 0.25em */
    padding: 0.625em 1.25em; /* 10px 20px = 0.625em 1.25em */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: large;
    font-weight: bold;
    box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5); /* 0 2px 4px */
}

.fb-login-button {
    background: #4267b2;
    color: white;
}

.google-login-button {
    background-color: #ffffff;
    color: rgb(101, 98, 133);
}

.icon {
    font-size: 1.5em; /* Equivalent of 'x-large' font size */
}
