.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  width: 3.125em; /* 50px = 3.125em */
  height: 3.125em; /* 50px = 3.125em */
  margin-top: -1.5625em; /* -25px = -1.5625em */
  z-index: 10;
  font-size: 1.25em; /* 20px = 1.25em */
  color: #000000;
  text-align: center;
  line-height: 3.125em; /* 50px = 3.125em */
  cursor: pointer;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev:before,
.slick-next:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5625em; /* -25px = -1.5625em */
  margin-left: -1.5625em; /* -25px = -1.5625em */
  width: 3.125em; /* 50px = 3.125em */
  height: 3.125em; /* 50px = 3.125em */
  border-radius: 50%;
  background-color: #f5f5f5;
}
