p {
    text-align: center;
    font-size: 1em;
}

.box {
    border-radius: 0.9375em; /* 15px = 0.9375em */
    box-shadow: 0 0.0625em 0.125em 0 rgba(0, 0, 0, 0.3); /* 0px 1px 2px = 0em 0.0625em 0.125em */
}

.course-img > img {
    width: 4.375em; /* 70px = 4.375em */
    height: 4.375em;
}

.course-sub {
    width: 12.5em; /* 200px = 12.5em */
}

.s-date-sp1 {
    font-size: 1em;
    color: rgb(226, 121, 0);
}

.date {
    font-size: 1em;
    font-weight: bold;
}

.subject {
    font-size: 1em;
    text-align: center;
}

.teacher {
    font-size: 1em;
}

.lesson-img > img {
    width: 4.375em; /* 70px = 4.375em */
    height: 4.375em;
}

.lesson-sub {
    width: 12.5em; /* 200px = 12.5em */
}

.cart-item-img > img {
    width: 5em; /* 80px = 5em */
    height: 5em;
}

.item-box {
    border-radius: 0; /* 0px */
    box-shadow: 0 0.0625em 0.125em 0 rgba(0, 0, 0, 0.1); /* 0px 1px 2px = 0em 0.0625em 0.125em */
}

@media (max-width: 62em) { /* 992px = 62em */
    .item-box {
        border-radius: 0.9375em; /* 15px = 0.9375em */
    }

    .cart-item-img > img {
        width: 4.375em; /* 70px = 4.375em */
        height: 4.375em;
    }
}

.price {
    width: 2.5em; /* 40px = 2.5em */
}

.width-80 {
    width: 80%;
}

.width-90 {
    width: 90%;
}

@media (max-width: 62em) { /* 992px = 62em */
    .width-80 {
        width: auto;
    }

    .width-90 {
        width: auto;
    }
}
