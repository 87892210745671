$primary-color: #007BFF;
$border-radius: 0.9375em; /* 15px = 0.9375em */
$box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2); /* 4px = 0.25em, 8px = 0.5em */

.cart-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 0.625em 0.625em 0.625em 2.5em; /* top, right, bottom, left */
    row-gap: 1em; /* 10px = 0.625em */
    column-gap: 4.4em;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    max-width:  1300px ;
   
    &:hover {
        box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.25); /* 6px = 0.375em, 12px = 0.75em */
        transform: translateY(-0.1875em); /* 3px = 0.1875em */
    }

    .subject-icon-container {
        display: flex;
        height: 5.625em; /* 90px = 5.625em */
        width: 5.625em; /* 90px = 5.625em */

        .frame {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .subject-icon {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    .subject-title-container {
        width: 11.25em; /* 180px = 11.25em */
        display: flex;
        justify-content: center;

        .subject-title {
            font-size: 0.9375em; /* 15px = 0.9375em */
            font-weight: bold;
            margin: 0;
        }
    }

    .rating-container {
        width: 9.375em; /* 150px = 9.375em */

        .teacher-rating {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.1875em; /* 3px = 0.1875em */

            .teacher-name {
                font-size: 0.9375em; /* 15px = 0.9375em */
                margin: 0;
            }

            .MuiRating-root {
                font-size: 1.25em; /* 20px = 1.25em */
            }
        }
    }

    .date-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1875em; /* 3px = 0.1875em */

        .date,
        .time {
            font-size: 0.9375em; /* 15px = 0.9375em */
            margin: 0;
        }
    }

    .price {
        font-size: 0.9375em; /* 15px = 0.9375em */
        font-weight: bold;
        color: $primary-color;
        text-align:center;
        width: 2.5em;;
    }
}

// Media Queries for Responsive Adjustments

@media only screen and (max-width: 1281px) {
    .cart-item {
        column-gap: 2.5em;
    }
}

@media only screen and (max-width: 1115px) {
    .cart-item {
        column-gap: 2.5em;

    }
}

@media only screen and (max-width: 885px) {
    .cart-item {
        padding: 0.625em 0.625em 0.625em 0.625em; /* top, right, bottom, left */
        column-gap: 1.5em;
    }
}

@media only screen and (max-width: 576px) {
    .cart-item {

        .subject-icon-container {
            height: 3.75em; /* 60px = 3.75em */
            width: 3.75em;
        }

        .subject-title-container {
            width: 7.5em; /* 120px = 7.5em */

            .subject-title {
                font-size: 0.8125em; /* 13px = 0.8125em */
            }
        }

        .rating-container {
            width: 7.5em; /* 120px = 7.5em */

            .teacher-rating {
                .teacher-name {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }

                .MuiRating-root {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                }
            }
        }

        .date-time {
            .date,
            .time {
                font-size: 0.8125em; /* 13px = 0.8125em */
            }
        }

        .price {
            font-size: 0.8125em; /* 13px = 0.8125em */
        }
    }
}

@media only screen and (max-width: 480px) {
    .cart-item {
        .subject-title-container {
            width: 7.2em;

            .subject-title {
                font-size: 0.8125em;
            }
        }
    }
}

@media only screen and (max-width: 428px) {
    .cart-item {
        column-gap:0.9em;

        .price {
            width:10em;
        }
    }
}

@media only screen and (max-width: 428px) {
    .cart-item {
        column-gap:0.5em;

        .price {
            width:5em;
        }
    }
}

@media only screen and (max-width: 391px) {
    .cart-item {
        column-gap:0.1em;

        .price {
            width:10em;
        }
    }
}

@media only screen and (max-width: 376px) {
    .cart-item {
        column-gap:0.3em;
       
        .subject-icon-container {
            height: 3.125em; /* 50px = 3.125em */
            width: 3.125em; /* 50px = 3.125em */
        }

        .subject-title-container {
            width: 6em; /* 105px = 6.5625em */

            .subject-title {
                font-size: 0.6875em; /* 11px = 0.6875em */
            }
        }

        .rating-container {
            width: 7em;

            .teacher-rating {
                .teacher-name {
                    font-size: 0.6875em; /* 11px = 0.6875em */
                }
            }
        }

        .price {
            font-size: 0.6875em; /* 11px = 0.6875em */
            width:8em;
        }
    }
}
