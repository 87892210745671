 .about-container {
    margin-top: 1.875em; /* 30px converted to em */
    margin-bottom: 3.125em; /* 50px converted to em */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1.25em; /* 20px converted to em */
    padding: 2em; /* Padding inside the container */
    background-color: #ffffff; /* White background for the box */
    border-radius: 0px; /* Rounded corners for the box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow around the box */
    margin-left: auto;
    margin-right: auto;
  }

  .x_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-container h1, .about-container h2 {
    color: #003366; /* A dark blue color */
  }
  
  .about-container p {
    max-width: 43.75em; /* 700px converted to em */
    line-height: 2.3;
    text-align: justify;
  }
  
  @media (max-width: 48em) { /* 768px converted to em */

    .about-container p {
      line-height: 1.6;
      margin: 0 auto;
    }
  }
  