.social > a {
    font-size: 1.875em; /*xx-large equivalent (30px)*/
    text-decoration: none;
    color: #ffffff;
}

.f-bar > span {
    font-size: 1em; /*medium equivalent (16px)*/
    text-decoration: none;
    color: #ffffff;
}

footer {
    background-color: #4a6458;
    color: #ffffff;
}

@media only screen and (max-width: 35.9375em) { /* 575px equivalent*/
    /* You can add any responsive changes for smaller screens if necessary*/
}

@media only screen and (max-width: 25.6875em) { /* 411px equivalent*/
    .f-bar > span {
        font-size: 0.875em; /*small equivalent (14px)*/
    }
}
