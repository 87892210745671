.daily-prog-btn {  
    border-radius: 0.625em; /* 10px = 0.625em */
    height: 2.5em; /* 40px = 2.5em */
    width: 12.5em; /* 200px = 12.5em */
}

.prog-container {
    display: grid;
    grid-template-columns: 30% 10% 10% 10% 10% 10% 10% 10%;
    grid-gap: 0.125em; /* 2px = 0.125em */
    background-color: #ffffff;
    padding-left: 1.5625em; /* 25px = 1.5625em */
    padding-right: 0.9375em; /* 15px = 0.9375em */
}

.dow {
    background-color: rgb(184, 182, 88); 
    text-align: center;
    padding: 0.625em 0; /* 10px 0px = 0.625em 0 */
    font-size: 0.9375em; /* 15px = 0.9375em */
}

.L_time {
    background-color: rgb(111, 177, 221); 
    text-align: center;
    padding: 0.625em 0; /* 10px 0px = 0.625em 0 */
    font-size: 0.9375em; /* 15px = 0.9375em */
}

.set_lesson {
    background-color: rgb(216, 145, 97); 
    color: rgb(216, 145, 97);
    text-align: center;
    padding: 0.625em 0; /* 10px 0px = 0.625em 0 */
    font-size: 0.9375em; /* 15px = 0.9375em */
}

.selected_lesson {
    background-color: rgb(166, 223, 162); 
    color: rgb(166, 223, 162);
    text-align: center;
    padding: 0.625em 0; /* 10px 0px = 0.625em 0 */
    font-size: 0.9375em; /* 15px = 0.9375em */
}

.no_content {
    background-color: rgb(194, 137, 32); 
    text-align: center;
    padding: 0.625em 0; /* 10px 0px = 0.625em 0 */
    font-size: 0.9375em; /* 15px = 0.9375em */
}
