.schedule{

    text-decoration: underline rgb(140, 12, 151);
    font-weight: bold;
    color:#8c0c97;
    font: size 1.2em;
}

.sdl-table{
    border-radius: 0px;
}