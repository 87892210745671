.titleStyle {
    font-weight: bold;
    color: #898989;
    font-size: 0.9375em; /* 15px = 0.9375em */
    /* Additional styling */
    text-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.2); /* 0px 1px 2px */
    margin-right: 0; /* 0px remains 0 */
    border: 0.0625em solid; /* 1px = 0.0625em */
    padding: 0.1875em; /* 3px = 0.1875em */
    /* Adding shadow to the border */
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25); /* 0px 2px 4px */
    border-color: rgb(201, 201, 201);
}

/* Default styling for larger screens */
.login-width {
    width: 28.125em; /* 450px = 28.125em */
}

/* Media query for smaller screens (smartphones) */
@media (max-width: 37.5em) { /* 600px = 37.5em */
    .login-width {
        width: 23.75em; /* 380px = 23.75em */
    }
}
