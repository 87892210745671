.cat_btn {
  border: none;
  outline: none;
  padding: 0.5625em 0.75em; /* 9px = 0.5625em, 12px = 0.75em */
  background-color: rgb(228, 228, 228);
  cursor: pointer;
  border-radius: 3.125em; /* 50px = 3.125em */
}

.cat_btn:hover {
  background-color: rgb(197, 197, 197);
}

.active {
  background-color: rgb(90, 89, 89);
  color: white;
}

.active:hover {
  background-color: rgb(59, 59, 59);
  color: white;
}
