.divider {
    display:flex;
}

.divider:after {
    content: "";
    flex: 1;
}
  
.gradient {
    align-items:stretch;
    padding-right: 1.2em;
    height: 2.2em;
    line-height:2.2em;
    color: rgb(255, 255, 255);
    background: rgb(128, 119, 227);
    font-weight:bold;
    font-size:large;
    width: 400px;
    border-radius:0px;    
}

.gradient:after {
    background: linear-gradient(to right, white,rgb(128, 119, 227));
}

.gradient_2 {
    align-items:stretch;
    padding-right: 0.5em;
    height: 1.8em;
    line-height:1.8em;
    color: rgb(255, 255, 255);
    background:rgb(128, 119, 227);
    font-weight:lighter;
    font-size:large;
    width: 400px;
    border-radius:0px;    
}

.gradient_2:after {
    background: linear-gradient(to right, rgb(255, 255, 255),rgb(128, 119, 227));
}


/* Media Query for screens smaller than 576px */
@media only screen and (max-width: 36em) { /* 576px = 36em */
    .gradient,
    .gradient_2 {
        width:300px; 
    }
}



  