.carousel {
    width: 100%;
    height:auto;
    margin: auto;

    .carousel-item {
        text-align: center;
        width: 100%;
        height: 100%;

        .carousel-img {
            width: 100%;
            height:auto; 
            vertical-align: middle;
        }
    }
}

@media only screen and (max-width: 440px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 205px;
                object-fit: cover; 
            }
        }
    }
}


@media only screen and (max-width: 431px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 195px;
            }
        }
    }  
}

@media only screen and (max-width: 415px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 187px;
            }
        }
    } 
}

@media only screen and (max-width: 394px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 175px;
            }
        }
    } 
}

@media only screen and (max-width: 376px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 170px;
            }
        }
    }
}

@media only screen and (max-width: 361px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 163px;
            }
        }
    } 
}

@media only screen and (max-width: 321px) {
    .carousel {
        .carousel-item {
            .carousel-img {
                height: 145px;
            }
        }
    }
}
