.course-container {
    position: relative;                       
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2); /* 4px = 0.25em, 8px = 0.5em */
    border-radius: 0.9375em; /* 15px = 0.9375em */
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    width: 90%;

    &:hover {
        box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.25); /* 6px = 0.375em, 12px = 0.75em */
        transform: translateY(-0.1875em); /* 3px = 0.1875em */
    }

    .course-box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.625em 0.625em 0.625em 5em; /* top, right, bottom, left */
        row-gap: 1em; /* 10px = 0.625em */
        
        .subject-icon-container {
            display: flex;
            height: 5.625em; /* 90px = 5.625em */
            width: 5.625em; /* 90px = 5.625em */

            .frame {
                width: 100%; 
                height: 100%; 
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                
                .subject-icon {
                    width: 100%; 
                    height: auto; 
                    object-fit: cover;
                }
            }
        }

        .syllabus-container {
            width: 11.25em; /* 180px = 11.25em */
            
            .syllabus-subject {
                display: flex;
                flex-direction: column;
                align-items: center; 

                .subject-title {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                    font-weight: bold;
                    margin: 0;  
                }
                              
                .syllabus-link {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                    margin: 0;                
                }
            }
        }

        .rating-container {
            width: 9.375em; /* 150px = 9.375em */
           
            .teacher-rating {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.1875em; /* 3px = 0.1875em */

                .teacher-name {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                    margin: 0;
                }
            
                .MuiRating-root {
                    font-size: 1.25em; /* 20px = 1.25em */
                }
            }
        }

        .start-date {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.1875em; /* 3px = 0.1875em */

            .date,
            .s-date {
                font-size: 0.9375em; /* 15px = 0.9375em */
                margin: 0;
            }

            .s-date {
                color: coral;
            }
        }
    }
}



// Media Queries for Responsive Adjustments

@media only screen and (max-width: 1196px) {
    .course-container {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) { 
    .course-container .course-box {
        padding: 0.625em 0.625em 0.625em 2.5em; /* top, right, bottom, left */
    }   
}


@media only screen and (max-width: 835px) {
    
}


@media only screen and (max-width: 576px) { 
    .course-container .course-box {
       
        .syllabus-container {
            width: 7.5em; /* 120px = 7.5em */
            
            .syllabus-subject {              
                .subject-title {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }
                
                .syllabus-link {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }
            }
        }
    }
}


@media only screen and (max-width: 30em) { /* 480px = 30em */
    .course-container .course-box {
       
        .subject-icon-container {
            height: 3.75em; /* 60px = 3.75em */
            width: 3.75em; /* 60px = 3.75em */
        }

        .syllabus-container {
            width: 7.5em; /* 120px = 7.5em */
            
            .syllabus-subject {
                .subject-title {
                    font-size: 0.75em; /* 12px = 0.75em */
                }
                
                .syllabus-link {
                    font-size: 0.75em; /* 12px = 0.75em */
                }
            }
        }

        .rating-container {
            width: 7.5em; /* 120px = 7.5em */
           
            .teacher-rating {
                .teacher-name {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }
            
                .MuiRating-root {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                }
            }
        }

        .start-date {
            .date,
            .s-date {
                font-size: 0.8125em; /* 13px = 0.8125em */
            }
        }
    }
}


@media only screen and (max-width: 361px) {
    .course-container .course-box {
       
        .subject-icon-container {
            height: 3.125em; /* 50px = 3.125em */
            width: 3.125em; /* 50px = 3.125em */
        }

        .syllabus-container {
            width: 6.5625em; /* 105px = 6.5625em */
            
            .syllabus-subject {
                .subject-title {
                    font-size: 0.6875em; /* 11px = 0.6875em */
                }
                
                .syllabus-link {
                    font-size: 0.6875em; /* 11px = 0.6875em */
                }
            }
        }

        .rating-container {
            width: 7.5em; /* 120px = 7.5em */
           
            .teacher-rating {
                .teacher-name {
                    font-size: 0.8125em; /* 13px = 0.8125em */
                }
            
                .MuiRating-root {
                    font-size: 0.9375em; /* 15px = 0.9375em */
                }
            }
        }

        .start-date {
            .date,
            .s-date {
                font-size: 0.8125em; /* 13px = 0.8125em */
            }
        }
    }
    
}

@media only screen and (max-width: 321px) {
    
}
