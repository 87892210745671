.bold-text {
    font-weight: bold;
}

.alert-container {
    width: 90%;
    max-width: 1200px; 
}

@media (max-width: 1196px) { 
    .alert-container {
        width: 100%;
    }
}
