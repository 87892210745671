.label-100-wrap {
    position: relative;
}

.label-100 {
    position: absolute;
    top: -1.375em; /* 22px = 1.375em */
    right: 0.625em; /* 10px = 0.625em */
    font-size: small;
}

.profile-gap { 
    gap: 2.1875em; /* 35px = 2.1875em */
}

.profile {
    display: flex;
    gap: 0.3125em; /* 5px = 0.3125em */
    flex-wrap: wrap;
}

.profile-name {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2.1875em; /* 35px = 2.1875em */
}

.profile-img {
    display: flex;
    flex-direction: column;
    gap: 1.25em; /* 20px = 1.25em */
    align-items: center;
    width: 29%;
    background: rgb(240, 245, 240);
}

.circle-frame {
    width: 6.25em; /* 100px = 6.25em */
    height: 6.25em; /* 100px = 6.25em */
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1875em solid orange; /* 3px = 0.1875em */
    box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.3); /* 0 6px 12px */
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: scale(1.1);
        box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.4); /* 0 8px 16px */
    }

    .profile-photo {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); /* 0 4px 8px */
    }
}

.profile-dtls {
    width: 40%;
    background: rgb(243, 240, 245);
}

.profile-settings {
    display: flex;
    flex-direction: column;
    gap: 0.3125em; /* 5px = 0.3125em */
    width: 29%;
}

.password {
    background: rgb(240, 245, 240);
}

.job {
    background: rgb(240, 245, 240);
}

@media (max-width: 62em) { /* 992px = 62em */
    .profile-img {
        width: 100%;
    }

    .profile-dtls {
        width: 100%;  
    }

    .profile-settings {
        width: 100%;
    }
}
