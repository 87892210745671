.error {
    font-size: 0.75em; /* x-small converted to approx 0.75em */
    color: red;
    text-align: start;
}

.invalid {
    border: 0.0625em solid red; /* 1px = 0.0625em */
}

.invalid:focus {
    border: 0.0625em solid red; /* 1px = 0.0625em */
    box-shadow: 0 0 0 0.125em rgba(255, 0, 0, 0.25); /* 0.2rem = 0.125em */
}

/* Custom decorated close button */
.custom-modal-close {
    background-color: #f44336;  /* Red background */
    color: white;               /* White "X" icon */
    border: none;               /* No border */
    border-radius: 20%;         /* Circle shape */
    width: 25px;                /* Button size */
    height: 25px;               /* Button size */
    position: absolute;         /* Allow precise placement */
    top: 12px;                  /* Adjust as needed for vertical alignment */
    left: 12px;                /* Adjust as needed for horizontal alignment */
    display: flex;              /* Center the "X" */
    align-items: center;
    justify-content: center;
    font-size: 20px;            /* Increase "X" size */
    line-height: 1;             /* Prevent extra spacing inside the button */
    cursor: pointer;            /* Add pointer cursor */
    transition: background-color 0.3s ease; /* Smooth hover effect */
}

.custom-modal-close:hover {
    background-color: #e53935;  /* Darker red on hover */
}


/* Optional: Extra padding for alignment */
.modal-header {
    padding-left: 20px;
}




