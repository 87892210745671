$primary-color: #007BFF;
$border-radius: 0.625em; /* 10px = 0.625em */
$box-shadow: 0em 0.0625em 0.125em 0em rgba(0, 0, 0, 0.3); /* 0px 1px 2px 0px converted */

.meeting-req-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius;
    box-shadow: $box-shadow;    
    padding: 0.75em 2em;
    gap: 0.7em;
    
    .subject-icon-container {
        display: flex;
        height: 5em; /* 80px = 5em */
        width: 5em;

        .frame {
            width: 100%;
            height: 100%; 
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .subject-icon {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
    }

    .date-time { 
        width: 6em; /* 140px = 8.75em */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.1875em; /* 3px = 0.1875em */

        .date,
        .time {
            font-size: 0.9375em; /* 15px = 0.9375em */
            margin: 0;
        }
    }

    .price { 
        width:2em; 
        font-size: 0.9375em; 
        font-weight: bold;
        color: $primary-color;
    }

    .lesson-sub {  
        width: 7em; /* 100px = 6.25em */
        font-size: 0.9375em;
        font-weight: bold;
        color: black;
        text-align: center;
    }

    .lesson-num {  
        width:2em; 
        font-size: 0.9375em;
        font-weight: bold;
        color: black;
        text-align: center;
    }

    .confirm {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25em; /* 20px = 1.25em */
        justify-content: center; /* Center elements horizontally */
        align-items: center; /* Center elements vertically */
    }

    .btn-custom {
        width:6em;
        border-radius: 5em; 
        font-size:1em;
    }
}


@media only screen and (max-width: 431px) {
    .meeting-req-box {

        
        .subject-icon-container {
            height: 3.5em; 
            width: 3.5em;
        }

        padding: 0.75em 1em;
        
        .date-time { 
            width: 5em;
            .date,
            .time {
                font-size: 0.7em; 
                margin: 0;
            }
        }
    
        .price { 
            width:2em; 
            font-size: 1em; 
        }
    
        .lesson-sub {  
            width:6em; 
            font-size:0.7em;
            font-weight:bold;
        }
    
        .lesson-num {  
            width:1em; 
            font-size:0.7em;
        }
    
        .confirm {
            gap: 1em; 
        }
    
        .btn-custom {
            width:6.5em;
            font-size:0.7em;
        }
    } 
}

@media only screen and (max-width: 321px) {
    .meeting-req-box {
    
        .date-time { 
            width: 4em;
            .date,
            .time {
                font-size: 0.6em; 
                margin: 0;
            }
        }
    
        .price { 
            width:2em; 
            font-size: 1em; 
        }
    
        .lesson-sub {  
            width:6em; 
            font-size:0.6em;
            font-weight:bold;
        }
    
        .lesson-num {  
            width:0.5em; 
            font-size:0.7em;
        }
    
        .confirm {
            gap: 1em; 
        }
    
        .btn-custom {
            width:7.5em;
            font-size:0.5em;
        }
    } 
}



