.price-info span {
    font-size: 0.75em; /* 12px = 0.75em */
    font-weight: 500;
}

.price-info {
    margin-bottom: 0.4375em; /* 7px = 0.4375em */
}

.price-box {
    box-shadow: 0 0.0625em 0.125em 0 rgba(0, 0, 0, 0.4); /* 0px 1px 2px = 0 0.0625em 0.125em */
    border-radius: 0.3125em; /* 5px = 0.3125em */
    background-color: rgb(244, 254, 255);
}

@media (max-width: 62em) { /* 992px = 62em */
    .price-box {
        border-radius: 0; /* Removed border-radius for smaller screens */
        box-shadow: 0 0.0625em 0.125em 0 rgba(0, 0, 0, 0.4); /* 0px 1px 2px */
    }
}
