/* Accessibility button with modern, minimalist gradient */
.accessibility-button {
  background: linear-gradient(135deg, #4a90e2, #357ABD); /* Sleek two-tone gradient */
  color: #fff;
  border: none; /* Remove border for a cleaner look */
  padding: 0.75em 1.25em 0.75em 0.625em; /* Convert 12px, 20px, 10px to em units */
  border-radius: 0 3.125em 3.125em 0; /* 50px = 3.125em */
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2); /* 4px 8px = 0.25em 0.5em */
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  z-index: 10000;
  font-size: 1.2em; /* Slightly larger font for readability */
}

/* On hover change color and add shadow */
.accessibility-button:hover {
  background: linear-gradient(135deg, #357ABD, #285C8E); /* Slightly darker gradient on hover */
  box-shadow: 0 0.375em 0.75em rgba(0, 0, 0, 0.3); /* 6px 12px = 0.375em 0.75em */
  transform: translateY(-0.125em); /* Lift the button slightly on hover */
}

/* Accessibility options menu */
.accessibility-options {
  background-color: #fff;
  border: 0.0625em solid #ddd; /* 1px = 0.0625em */
  border-radius: 0.3125em; /* 5px = 0.3125em */
  padding: 0.625em; /* 10px = 0.625em */
  box-shadow: 0 0.125em 0.625em rgba(0, 0, 0, 0.1); /* 2px 10px = 0.125em 0.625em */
  width: 12.5em; /* 200px = 12.5em */
  display: flex;
  flex-direction: column;
  gap: 0.625em; /* 10px = 0.625em */
  z-index: 9999; /* Keep the menu just below the button */
}

/* Style individual option buttons */
.accessibility-options button {
  padding: 0.625em; /* 10px = 0.625em */
  border: none;
  background-color: #4a90e2; /* Modern blue color */
  color: white;
  cursor: pointer;
  border-radius: 0.1875em; /* 3px = 0.1875em */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.accessibility-options button:hover {
  background-color: #357ABD; /* Slightly darker blue on hover */
  transform: scale(1.05); /* Slightly scale up on hover */
}

/* High contrast mode */
.high-contrast {
  background-color: #000;
  color: #fff;
}

.high-contrast a {
  color: #ffff00;
}

.high-contrast button {
  background-color: #333;
  color: #fff;
  border: 0.0625em solid #fff; /* 1px = 0.0625em */
}
