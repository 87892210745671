.card-container {
    position: relative;  
    width: 14em; // Default width for larger screens
    max-height: 22em; // Consistent max-height for all screens
    box-shadow: -0.1875em 0.25em 0.375em -0.125em rgba(0, 0, 0, 0.2); // 3px 4px 6px -2px
    border-radius: 0em; // 8px = 0.5em
    overflow: hidden;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    padding-bottom: 1.25em; // 20px = 1.25em

    &:hover {
        box-shadow: -0.1875em 0.375em 0.75em -0.125em rgba(0, 0, 0, 0.25); // 3px 6px 12px -2px
        transform: translateY(-0.1875em); // 3px = 0.1875em
    }

    .subject-icon-container {
        display: flex;
        height: 5em; // 80px = 5em
        background-color: white;

        .frame {
            width: 40%; 
            height: 100%; 
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            .subject-icon {
                width: 100%; 
                max-height: 100%;
                object-fit: cover;
            }
        }
    }

    .course-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.625em; // 10px = 0.625em

        .syllabus-container { 
            .syllabus-subject {
                display: flex;
                flex-direction: column;
                align-items: center; 

                .subject-title {
                    font-size: 0.9375em; // 15px = 0.9375em
                    font-weight: bold;
                    padding: 0.625em; // Consistent padding for all sides
                    margin-top: 0.625em;
                    height: 2.5em; // 40px = 2.5em
                    text-align: center;
                }

                .syllabus-link {
                    font-size: 1em; // Adjusted font-size for better consistency
                    margin: 0;
                }
            }
        }

        .rating-container {
            .teacher-rating {
                display: flex;
                flex-direction: column;
                align-items: center;

                .teacher-name {
                    font-size: 0.9375em; // 15px = 0.9375em
                    margin: 0;
                }

                .MuiRating-root {
                    font-size: 1em; // 16px = 1em
                }
            }
        }

        .start-date {
            display: flex;
            flex-direction: column;
            align-items: center;

            .date,
            .s-date {
                font-size: 0.8125em; // 13px = 0.8125em
                margin: 0;
            }

            .s-date {
                color: coral;
            }
        }

        .reg-btn {
            width: 90%; 
            font-weight: bold;
            margin: auto;
            display: block;
        }
    }
}

// Media Queries for Responsive Adjustments
@media only screen and (max-width: 1281px) {
    .card-container {
        width: 13.3em;
    }
}

@media only screen and (max-width: 1115px) {
    .card-container {
        width: 16.3em;
    }
}

@media only screen and (max-width: 885px) {
    .card-container {
        width: 17.3em;
    }
}

@media only screen and (max-width: 835px) {
    .card-container {
        width: 16em;
    }
}

@media only screen and (max-width: 821px) {
    .card-container {
        width: 15.8em;
    }
}

@media only screen and (max-width: 801px) {
    .card-container {
        width: 15.4em;
    }
}

@media only screen and (max-width: 769px) {
    .card-container {
        width: 14.7em;
    }
}

@media only screen and (max-width: 431px) {
    .card-container {
        width: 11.9em;
    }

    .course-box {
        .syllabus-container {
            .syllabus-subject {
                .subject-title {
                    font-size: 0.8125em; // 13px = 0.8125em
                }
            }
        }

        .rating-container {
            .teacher-rating {
                .teacher-name {
                    font-size: 0.8125em; // 13px = 0.8125em
                }
            }
        }
    }
}

@media only screen and (max-width: 415px) {
    .card-container {
        width: 11.5em;
    }
}

@media only screen and (max-width: 394px) {
    .card-container {
        width: 10.8em;
    }

    .course-box {
        .syllabus-container {
            .syllabus-subject {
                .subject-title {
                    font-size: 0.75em; // 12px = 0.75em
                }
            }
        }

        .rating-container {
            .teacher-rating {
                .teacher-name {
                    font-size: 0.75em; // 12px = 0.75em
                }

                .MuiRating-root {
                    font-size: 0.875em; // 14px = 0.875em
                }
            }

            .start-date {
                .date,
                .s-date {
                    font-size: 0.75em; // 12px = 0.75em
                }
            }

            .reg-btn {
                font-size: 0.875em; // Reduced button text size for smaller screens
            }
        }
    }
}

@media only screen and (max-width: 376px) {
    .card-container {
        width: 10.4em;
    }
}

@media only screen and (max-width: 361px) {
    .card-container {
        width:9.9em;

        .course-box {
            .syllabus-container {
                .syllabus-subject {
                    .subject-title {
                        font-size: 0.6875em; // 11px = 0.6875em
                        height: 2em; // Reduced height for small screens
                    }

                    .syllabus-link {
                        font-size: 0.875em; // Reduced font-size for smaller screens
                    }
                }
            }

            .rating-container {
                .teacher-rating {
                    .teacher-name {
                        font-size: 0.6875em; // 11px = 0.6875em
                    }

                    .MuiRating-root {
                        font-size: 0.75em; // 12px = 0.75em
                    }
                }
            }

            .start-date {
                .date,
                .s-date {
                    font-size: 0.6875em; // 11px = 0.6875em
                }
            }

            .reg-btn {
                font-size: 0.75em; // Reduced button text size
                width: 85%; // Slightly reduce width for better spacing
            }
        }
    }
}

@media only screen and (max-width: 321px) {
    .card-container {
        width: 8.2em;

        .course-box {
            .syllabus-container {
                .syllabus-subject {
                    .subject-title {
                        font-size: 0.6875em; // 11px = 0.6875em
                    }
                }
            }
        }
    }
}


