.nav {
  gap: 1.5625em; /*25px equivalent*/
}

.nav-item {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
}

.nav-item:hover {
  color: #e79343;
}

.nav-drop {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
}

.offcanvas-menu {
  background-color: #0c0c0c;
}

/* Default styles */
.brand-pos, .tch_brand-pos {
  left: 1em; 
  top: 1.3em;
  position: absolute;
}

/* Large screens (lg) - max-width: 992px (62em) */
@media (max-width: 991px) { /* 992px equivalent */
  .nav {
    gap: 1.25em; /* 20px equivalent */
  }

  .tch_brand-pos {
    top: 0.8em;
  }
}

/* Medium screens (md) - max-width: 768px (48em) */
@media (max-width: 48em) { /* 768px equivalent */
  .brand-pos{
    top: 0.8em;
  }
}

/* Small screens (sm) - max-width: 576px (36em) */
@media (max-width: 36em) { /* 576px equivalent */

}


